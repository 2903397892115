<template>
    <v-card>
        <v-card-title>

        </v-card-title>
        <v-card-text>
            
            <v-row justify="center">
                <v-col cols="auto" >
                    <s-date label="Fecha Inicio"
                        v-model="item.CipDateBegin"
                        @input="initialize()"
                        >
                    </s-date>
                </v-col>
                <v-col cols="auto">
                    <s-date label="Fecha Fin"
                        v-model="item.CipDateEnd"
                        @input="initialize()"
                        >
                    </s-date>
                </v-col>
            
                <v-col cols="auto">
                    <s-select-client
                        clearable
                        label="Cliente"
                        v-model="item.CumID"
                        ref="txtCumID"
                        full
                        @input="initialize()"
                    >
                    </s-select-client>
                </v-col> 
               
                <v-col cols="auto">                              
                    <s-select-definition
                        clearable
                        label="Tipo corte"
                        :def="1429"
                        v-model="item.TypeCut"
                        @input="initialize()"
                    ></s-select-definition>    
                </v-col>
                <v-col cols="auto">
                    <s-select-definition
                        clearable
                        label="Tipo Empaque"
                        v-model="item.TypePacking"
                        :def="1435"
                        @input="initialize()"
                    ></s-select-definition>
                </v-col>
                <v-col cols="auto">
                    <s-select-definition
                        clearable
                        :def="1173"
                        v-model="item.TypeCultive"
                        label="Cultivo"
                        @input="initialize()"
                        ></s-select-definition>    
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-data-table  
                        dense
                        :headers="headersPalletsDispatched"
                        :items="PalletsDispatched">

                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>

        </v-card-actions>
    </v-card>
</template>
<script>

import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";
import _sPicking from "@/services/FrozenProduction/FrzChamberPicking.js";   
export default{
    components: {SSelectClient},
    data()
    {
        return {
            item: {},
            PalletsDispatched: [],
            headersPalletsDispatched: [
                { text: 'PalletGroupID', value: 'PalletGroupID', sortable: false   },
                { text: 'TraceabilityID', value: 'TraceabilityID', sortable: false   },
                { text: 'Cliente', value: 'CumName', sortable: false   },
                { text: 'Marca', value: 'CbdBrans', sortable: false   },
                { text: "Variedad", value: "VrtDescription"},
                { text: 'Corte', value: 'TypeCutName', sortable: false   },   
                { text: 'Presentacion', value: 'TypePresentationName', sortable: false   },    
                { text: 'Cajas', value: 'TotalBox', sortable: false   },
                { text: 'Peso', value: 'TotalBagWeight', sortable: false   },                
                { text: 'Estado F.', value: 'StateFinalString', sortable: false   },
                { text: 'Cultivo', value: 'TypeCultiveName', sortable: false   },
                { text: 'Lote', value: 'Lote', sortable: false   },          
                { text: 'FV', value: 'DueDateString', sortable: false   },     
                { text: 'T. Conge.', value: 'CecDescription', sortable: false   },     
                
            ]
        }
    },
    methods:{
        initialize(){
            _sPicking.Dispatched(this.item, this.$fun.getUserID()).then(r => { 
                if(r.status == 200)
                {
                    this.PalletsDispatched = r.data;
                }

            });
        }
    }, 
    created(){
        this.initialize();
    }
}
</script>